import React, { useState, useEffect } from "react";

import { ReactComponent as Logo } from "../../../assets/svg/logos/logo-light.svg";
import "./style.scss";

const Loader = ({ delay = 200 }) => {
  const [timer, setTimer] = useState(null);
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    const displayLoader = () => {
      setIsDisplayed(true);
    };

    if (!timer) setTimer(setTimeout(displayLoader, delay));

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [delay, timer]);

  const loaderClass = isDisplayed ? "loader displayed" : "loader";

  return (
    <div className={loaderClass}>
      <Logo />
      <div className="loader__bg">
        <div className="loader__track">
          <div className="loader__fill" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
