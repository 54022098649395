import React, { useState, useEffect } from "react";

import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";

import "./style.scss";

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(null);

  useEffect(() => {
    document.body.style.overflowY = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  return (
    <div className="layout">
      <Header menuState={{ isMenuOpen, setIsMenuOpen }} />
      <div className="layout__page-container">
        <Menu menuState={{ isMenuOpen, setIsMenuOpen }} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
