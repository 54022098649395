import React from "react";

const MenuIcon = ({ isMenuOpen }) => {
  const generateMenuIconClass = (isOpen) => {
    if (isOpen === true) return "hamburger-icon open";
    else if (isOpen === false) return "hamburger-icon close";
    else return "hamburger-icon";
  };

  const menuIconClass = generateMenuIconClass(isMenuOpen);

  return (
    <svg
      className={menuIconClass}
      width="40"
      height="25"
      viewBox="0 0 40 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="hamburger-icon__mid"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 14H0V11H40V14Z"
        fill="#111"
      />
      <path
        className="hamburger-icon__bot"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 25H0V22H40V25Z"
        fill="#111"
      />
      <path
        className="hamburger-icon__top"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 3H0V0H40V3Z"
        fill="#111"
      />
    </svg>
  );
};

export default MenuIcon;
