export const main_nav_variants = {
  open: () => ({
    scaleY: 1,
    transformOrigin: "bottom center",
    transition: {
      duration: 0.3,
      delayChildren: 0.2,
      staggerChildren: 0.05,
      staggerDirection: -1,
      type: "tween",
      delay: 0,
      easing: "linear",
    },
  }),
  closed: () => ({
    scaleY: 0,
    transformOrigin: "bottom center",
    transition: {
      duration: 0.3,
      delayChildren: 0,
      staggerChildren: 0.05,
      staggerDirection: -1,
      type: "tween",
      delay: 0.6,
      easing: "linear",
    },
  }),
};

export const main_nav_child_variants = {
  open: {
    opacity: 1,
    y: 0,
  },
  closed: {
    opacity: 0,
    y: "100px",
  },
};

export const sub_nav_variants = {
  open: () => ({
    scaleX: 1,
    transformOrigin: "left center",
    transition: {
      delay: 0,
      delayChildren: 0.4,
      ease: [0.19, 1, 0.22, 1],
      duration: 0.7,
      staggerChildren: 0.1,
    },
  }),
  closed: () => ({
    scaleX: 0,
    transformOrigin: "left center",
    transition: {
      delay: 0.4,
      delayChildren: 0,
      ease: [0.19, 1, 0.22, 1],
      duration: 0.6,
      staggerChildren: 0.1,
    },
  }),
};

export const fade_variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

export const fade_low_variants = {
  open: {
    opacity: 0.2,
  },
  closed: {
    opacity: 0,
  },
};

export const social_link_variants = {
  open: {
    scaleX: 1,
    transformOrigin: "right center",
    transition: {
      delay: 0.1,
      delayChildren: 0.5,
      ease: [0.19, 1, 0.22, 1],
      duration: 0.6,
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleX: 0,
    transformOrigin: "right center",
    transition: {
      delay: 0.4,
      delayChildren: 0,
      ease: [0.19, 1, 0.22, 1],
      duration: 0.6,
      staggerChildren: 0.1,
    },
  },
};

export const social_link_child_variants = {
  open: {
    opacity: 1,
    x: 0,
  },
  closed: {
    opacity: 0,
    x: "-100px",
  },
};

export const contact_link_variants = {
  open: {
    scaleY: 1,
    transformOrigin: "top center",
    transition: {
      delay: 0.1,
      delayChildren: 0.3,
      ease: [0.19, 1, 0.22, 1],
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
  closed: {
    scaleY: 0,
    transformOrigin: "top center",
    transition: {
      delay: 0.4,
      delayChildren: 0,
      ease: [0.19, 1, 0.22, 1],
      duration: 0.6,
      staggerChildren: 0.1,
    },
  },
};

export const scale_y_init = {
  scaleY: 0,
};
export const scale_x_init = {
  scaleX: 0,
};
