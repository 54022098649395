import React from "react";

import { ReactComponent as Facebook } from "../../assets/svg/social-media/facebook.svg";
import { ReactComponent as Zillow } from "../../assets/svg/social-media/zillow.svg";
import { ReactComponent as Linkedin } from "../../assets/svg/social-media/linkedin.svg";
import { ReactComponent as Google } from "../../assets/svg/social-media/google.svg";

export const FOOTER_LINKS = [
  { name: "about", href: "/about" },
  { name: "contact", href: "/contact" },
  { name: "buyers", href: "/buyers" },
  { name: "sellers", href: "/sellers" },
  { name: "listings", href: "/listings" },
  { name: "latest", href: "/latest" },
  { name: "reviews", href: "/reviews" },
];

export const SOCIAL_LINKS = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/pettigrewproperties/",
    icon: <Facebook />,
  },
  {
    name: "LinkedIn",
    href: "#",
    icon: <Linkedin />,
  },
  {
    name: "Google",
    href: "#",
    icon: <Google />,
  },
  {
    name: "Zillow",
    href: "https://www.zillow.com/profile/jimpettigrew/",
    icon: <Zillow />,
  },
];

export const getMainMenuLinks = (windowWidth) => {
  if (windowWidth >= 700)
    return [
      { name: "about", href: "/about" },
      { name: "contact", href: "/contact" },
      { name: "listings", href: "/listings" },
      { name: "reviews", href: "/reviews" },
    ];
  else
    return [
      { name: "about", href: "/about" },
      { name: "buyers", href: "/buyers" },
      { name: "sellers", href: "/sellers" },
      { name: "contact", href: "/contact" },
      { name: "listings", href: "/listings" },
      { name: "reviews", href: "/reviews" },
    ];
};

export const SUB_MENU_BUYERS = [
  { name: "home finder", href: "/home-finder" },
  { name: "FAQ", href: "/buyers#FAQ" },
  { name: "resources", href: "/buyers#resources" },
];

export const SUB_MENU_SELLERS = [
  { name: "home value", href: "/home-value" },
  { name: "services", href: "/services" },
  { name: "FAQ", href: "/sellers#FAQ" },
  { name: "resources", href: "/sellers#resources" },
];

export const CONTACT_LINKS_JIM = [
  { name: "206-948-5590", href: "tel: 2069485590" },
  {
    name: "jim@pettigrewproperties.com",
    href: "mailto: jim@pettigrewproperties.com",
  },
];

export const CONTACT_LINKS_JASON = [
  { name: "206-310-3920", href: "tel: 2063103920" },
  {
    name: "jason@pettigrewproperties.com",
    href: "mailto: jason@pettigrewproperties.com",
  },
];
