import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useScrollPosition } from "../hooks/scrollPosition.hook";

export const LayoutContext = React.createContext();

export const LayoutProvider = ({ children }) => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [animateHeaderIn, setAnimateHeaderIn] = useState(false);
  const [animateHeaderOut, setAnimateHeaderOut] = useState(false);
  // Show header without animation
  const [showHeader, setShowHeader] = useState(false);

  const { scrollPosition, scrollDirection } = useScrollPosition();

  const location = useLocation();
  const { pathname, hash } = location;

  useEffect(() => {
    setIsHeaderFixed(false);
    setShowHeader(true);
    setAnimateHeaderOut(false);
  }, [pathname, hash]);

  useEffect(() => {
    if (scrollPosition > 0) setIsHeaderFixed(true);
    else setIsHeaderFixed(false);

    if (scrollPosition > 150 && scrollDirection === 1) {
      setAnimateHeaderIn(true);
      setAnimateHeaderOut(false);
    } else if (scrollPosition > 150 && scrollDirection === -1) {
      setAnimateHeaderIn(false);
      setAnimateHeaderOut(true);
    }
  }, [scrollPosition, scrollDirection]);

  const value = {
    isHeaderFixed,
    animateHeaderIn,
    animateHeaderOut,
    showHeader,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
