import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Loader from "./components/Loader";
import Layout from "./Layout";
import { routeList } from "../routes";

const App = () => {
  const routes = routeList.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      name={route.name}
      render={(props) => <route.component {...props} />}
    />
  ));

  return (
    <main>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Layout>{routes}</Layout>
        </Switch>
      </Suspense>
    </main>
  );
};

export default App;
