import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as LogoLight } from "../../../assets/svg/logos/logo-light.svg";
import { ReactComponent as FooterArrow } from "../../../assets/svg/arrows/footer-arrow-white.svg";
import { FOOTER_LINKS, SOCIAL_LINKS } from "../constant";

import "./style.scss";

const Footer = () => {
  const navItems = FOOTER_LINKS.map(({ name, href }) => (
    <li key={name}>
      <Link to={href}>{name}</Link>
    </li>
  ));

  const socialItems = SOCIAL_LINKS.map(({ name, icon, href }) => (
    <li key={name}>
      <a rel="noopener noreferrer" target="_blank" href={href}>
        {icon}
      </a>
    </li>
  ));
  return (
    <footer className="site-footer">
      <div className="site-footer__cta">
        <div>
          <p>Still have questions?</p>
          <Link to="/contact">
            GET IN TOUCH <FooterArrow />
          </Link>
        </div>
      </div>
      <div className="site-footer__main">
        <div>
          <Link to="/" className="site-footer__logo">
            <LogoLight />
            <p>PETTIGREW</p>
            <p>PROPERTIES</p>
          </Link>
          <div className="site-footer__links">
            <div className="site-footer__contact">
              <div>
                <h2>Jim P.</h2>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="tel:2069485590"
                >
                  206-948-5590
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="mailto:jim@pettigrewproperties.com"
                >
                  jim@pettigrewproperties.com
                </a>
              </div>
              <div>
                <h2>Jason P.</h2>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="tel:2063103929"
                >
                  206-310-3920
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="mailto:jason@pettigrewproperties.com"
                >
                  jason@pettigrewproperties.com
                </a>
              </div>
            </div>
            <nav className="site-footer__nav">
              <ul>{navItems}</ul>
            </nav>
            <ul className="site-footer__social">{socialItems}</ul>
          </div>
        </div>
      </div>
      <div className="site-footer__attributions">
        <div>
          <p>Copyright © {new Date().getFullYear()} Pettigrew Properties</p>
          <p>Built by H &amp; P</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
