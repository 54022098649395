import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { LayoutProvider } from "./App/context/layout.context";

import ScrollToTop from "./App/utilities/ScrollToTop";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/scss/global.scss";

const app = (
  <BrowserRouter>
    <SnackbarProvider>
      <LayoutProvider>
        <App />
      </LayoutProvider>
    </SnackbarProvider>
    <ScrollToTop />
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
