import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

import { useWindowSize } from "../../hooks/windowSize.hook";
import { buildS3Uri } from "../../helpers/s3";
import {
  main_nav_variants,
  main_nav_child_variants,
  sub_nav_variants,
  fade_variants,
  fade_low_variants,
  social_link_variants,
  social_link_child_variants,
  contact_link_variants,
  scale_x_init,
  scale_y_init,
} from "./motion";
import {
  getMainMenuLinks,
  SUB_MENU_BUYERS,
  SUB_MENU_SELLERS,
  SOCIAL_LINKS,
  CONTACT_LINKS_JIM,
  CONTACT_LINKS_JASON,
} from "../constant";

import "./style.scss";

const HeaderMenu = ({ menuState }) => {
  const { isMenuOpen: isOpen, setIsMenuOpen: setIsOpen } = menuState;

  const animate = isOpen === true ? "open" : "closed";

  const { width: windowWidth } = useWindowSize();

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const MAIN_MENU_LINKS = getMainMenuLinks(windowWidth);

  const mainMenuItems = MAIN_MENU_LINKS.map(({ name, href }) => (
    <motion.li
      onClick={handleCloseMenu}
      variants={main_nav_child_variants}
      key={name}
    >
      <Link to={href}>{name}</Link>
    </motion.li>
  ));

  const buyerMenuItems = SUB_MENU_BUYERS.map(({ name, href }) => {
    const link =
      name === "FAQ" || "resources" ? (
        <HashLink to={href}>{name}</HashLink>
      ) : (
        <Link to={href}>{name}</Link>
      );

    return (
      <li onClick={handleCloseMenu} key={name}>
        {link}
      </li>
    );
  });
  const sellerMenuItems = SUB_MENU_SELLERS.map(({ name, href }) => {
    const link =
      name === "FAQ" || "resources" ? (
        <HashLink to={href}>{name}</HashLink>
      ) : (
        <Link to={href}>{name}</Link>
      );
    return (
      <li onClick={handleCloseMenu} key={name}>
        {link}
      </li>
    );
  });

  const socialItems = SOCIAL_LINKS.map(({ icon, href }, index) => (
    <li key={index}>
      <a rel="noreferrer noopener" target="_blank" href={href}>
        {icon}
      </a>
    </li>
  ));

  const jimContactItems = CONTACT_LINKS_JIM.map(({ name, href }) => (
    <li key={name}>
      <a rel="noreferrer noopener" target="_blank" href={href}>
        {name}
      </a>
    </li>
  ));

  const jasonContactItems = CONTACT_LINKS_JASON.map(({ name, href }) => (
    <li key={name}>
      <a rel="noreferrer noopener" target="_blank" href={href}>
        {name}
      </a>
    </li>
  ));

  const bgImageSrc = buildS3Uri({
    prefix: "images/menu",
    key: "fremont-home-front-view-min.png",
  });

  const headerMenuClass = isOpen ? "header-menu open" : "header-menu";

  return (
    <div className={headerMenuClass}>
      <motion.nav
        animate={animate}
        variants={main_nav_variants}
        initial={scale_y_init}
        className="header-menu__main-nav"
      >
        <motion.img
          variants={fade_low_variants}
          src={bgImageSrc}
          ariaLabel="background image"
          alt="background"
        />
        <ul>{mainMenuItems}</ul>
      </motion.nav>
      <motion.nav
        animate={animate}
        variants={sub_nav_variants}
        initial={scale_x_init}
        className="header-menu__sub-nav"
      >
        <motion.div
          variants={fade_variants}
          className="header-menu__sub-nav-layout"
        >
          <div className="header-menu__sub-nav--buyers">
            <h2>
              <Link onClick={handleCloseMenu} to="/buyers">
                buyers
              </Link>
            </h2>
            <ul className="header-menu__buyer-nav-list">{buyerMenuItems}</ul>
          </div>
          <div className="header-menu__sub-nav--sellers">
            <h2>
              <Link onClick={handleCloseMenu} to="/sellers">
                sellers
              </Link>
            </h2>
            <ul className="header-menu__seller-nav-list">{sellerMenuItems}</ul>
          </div>
        </motion.div>
      </motion.nav>
      <motion.div
        animate={animate}
        variants={social_link_variants}
        initial={scale_x_init}
        className="header-menu__social-links"
      >
        <div>
          <motion.h2 variants={social_link_child_variants}>social</motion.h2>
          <motion.ul variants={fade_variants}>{socialItems}</motion.ul>
        </div>
      </motion.div>
      <motion.div
        animate={animate}
        variants={contact_link_variants}
        initial={scale_y_init}
        className="header-menu__contact-links"
      >
        <motion.div variants={fade_variants}>
          <h2>Jim P.</h2>
          <ul>{jimContactItems}</ul>
        </motion.div>
        <motion.div variants={fade_variants}>
          <h2>Jason P.</h2>
          <ul>{jasonContactItems}</ul>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HeaderMenu;
