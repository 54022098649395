import { useState, useEffect } from "react";

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(-1);

  useEffect(() => {
    const scrollYPos = window.pageYOffset || document.documentElement.scrollTop;
    let lastScrollPos = scrollYPos;

    const handleScroll = (e) => {
      const currentScrollPos = e.currentTarget.scrollY;

      setScrollPosition(currentScrollPos);

      if (lastScrollPos > currentScrollPos) setScrollDirection(1);
      else setScrollDirection(-1);

      lastScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { scrollDirection, scrollPosition };
};
