import React, { useContext } from "react";
import { Link } from "react-router-dom";

import MenuIcon from "./MenuIcon";
import { ReactComponent as Logo } from "../../../assets/svg/logos/logo.svg";
import { LayoutContext } from "../../context/layout.context";
import "./style.scss";

const Header = ({ menuState }) => {
  const { isMenuOpen, setIsMenuOpen } = menuState;

  const {
    isHeaderFixed,
    animateHeaderOut,
    animateHeaderIn,
    showHeader,
  } = useContext(LayoutContext);

  const handleToggleMenu = () => {
    const newOpenState = !isMenuOpen;
    setIsMenuOpen(newOpenState);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const getHeaderClass = () => {
    const headerFixedClass = isHeaderFixed
      ? "site-header fixed"
      : "site-header";
    const animateInClass = animateHeaderIn ? "animate-in" : "";
    const animateOutClass = animateHeaderOut ? "animate-out" : "";
    const showHeaderClass = showHeader ? "show-header" : "";
    return `${headerFixedClass} ${animateInClass} ${animateOutClass} ${showHeaderClass}`;
  };

  const headerClass = getHeaderClass();

  return (
    <header className={headerClass}>
      <div className="site-header__layout">
        <Link
          onClick={handleCloseMenu}
          to="/"
          className="site-header__logo-container"
        >
          <Logo />
          <div>
            <p>PETTIGREW</p>
            <p>PROPERTIES</p>
          </div>
        </Link>
        <button
          onClick={handleToggleMenu}
          className="site-header__menu-button"
          aria-label="open menu"
        >
          <MenuIcon isMenuOpen={isMenuOpen} />
        </button>
      </div>
    </header>
  );
};

export default Header;
