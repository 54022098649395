import React from "react";

const Home = React.lazy(() => import("./App/pages/Home"));
const About = React.lazy(() => import("./App/pages/About"));
const Contact = React.lazy(() => import("./App/pages/Contact"));
const Buyers = React.lazy(() => import("./App/pages/Customer/Buyers"));
const Sellers = React.lazy(() => import("./App/pages/Customer/Sellers"));
const HomeInfoForm = React.lazy(() => import("./App/pages/HomeInfoForm"));
const ListingPost = React.lazy(() => import("./App/pages/ListingPost"));
const Listings = React.lazy(() => import("./App/pages/Listings"));
const Reviews = React.lazy(() => import("./App/pages/Reviews"));
const Services = React.lazy(() => import("./App/pages/Services"));

export const routeList = [
  {
    path: "/services",
    exact: true,
    name: "Services",
    component: (props) => <Services {...props} />,
  },
  {
    path: "/reviews",
    exact: true,
    name: "Reviews",
    component: (props) => <Reviews {...props} />,
  },
  {
    path: "/listings",
    exact: true,
    name: "Listings",
    component: (props) => <Listings {...props} />,
  },
  {
    path: "/listing/:entryId",
    exact: true,
    name: "Listing Post",
    component: (props) => <ListingPost {...props} />,
  },
  {
    path: "/home-value",
    exact: true,
    name: "Home Finder",
    component: (props) => <HomeInfoForm type="valuation" {...props} />,
  },
  {
    path: "/home-finder",
    exact: true,
    name: "Home Finder",
    component: (props) => <HomeInfoForm type="finder" {...props} />,
  },
  {
    path: "/sellers",
    exact: true,
    name: "Sellers",
    component: (props) => <Sellers {...props} />,
  },
  {
    path: "/buyers",
    exact: true,
    name: "Buyers",
    component: (props) => <Buyers {...props} />,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    component: (props) => <Contact {...props} />,
  },
  {
    path: "/about",
    exact: true,
    name: "About",
    component: (props) => <About {...props} />,
  },
  {
    path: "/",
    exact: true,
    name: "Home",
    component: (props) => <Home {...props} />,
  },
];
